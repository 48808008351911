<template>
  <v-container>
    <section id="about-me" class="mt-10">
      <!-- <h2 class="headline">About Me</h2>
      <v-divider class="my-4"></v-divider> -->
      <v-row>
        <v-col cols="12" sm="6" align="center">
          <v-img src="../assets/me.jpeg" alt="Profile Image" width="50%" height="auto" style="border-radius: 5%;"></v-img>
          <ContactMe />
        </v-col>
        <v-col cols="12" sm="6">
          <v-card class="px-5 pb-5" color="brown-lighten-3">
          <section class="bio-section mt-10">
            <!-- <h1>🚀 Crafting Code, Unleashing Innovation: Meet Zach Egner</h1><br> -->
            <p align="justify">
              🌟 Welcome to the digital realm where lines of code become gateways to boundless possibilities. I'm Zach,
              your friendly neighborhood software magician, with over a year of experience conjuring digital wonders. 🎩✨
            </p>
            <br>
            <p align="justify">
              💻 As a seasoned Node.js Developer, I've woven my way through the intricate tapestry of full-stack
              applications, unraveling complex challenges with an unwavering commitment to excellence. My code isn't just
              lines and functions; it's a symphony of seamless interactions that transform ideas into tangible,
              user-friendly experiences.
            </p>
            <br>
            <p align="justify">
              ☁️ I've mastered AWS cloud services, creating scalable, resilient, and efficient solutions. Whether it's
              optimizing serverless architectures or orchestrating cloud resources, AWS bends to my will.
            </p>
            <br>
            <p align="justify">
              🚀 What sets me apart? My relentless pursuit of innovation. I thrive on pushing boundaries, using emerging
              technologies to fuel my drive for continuous improvement.
            </p>
            <br>
            <p align="justify">
              💡 I see challenges as opportunities to learn, grow, and innovate. Whether it's debugging a labyrinthine
              codebase or architecting a system from scratch, I turn obstacles into stepping stones, collaborating with
              teams to create remarkable projects.
            </p>
          </section>
        </v-card>
          <!-- <UnderConstruction /> -->
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
import ContactMe from './ContactMe.vue';

//import UnderConstruction from './UnderConstruction.vue';

export default {
    name: "AboutMe",
    components: { ContactMe }
};
</script>

<style scoped>/* Add your CSS styles here */
/* You can customize styles for specific elements here */</style>
