<template>
  <v-container>
    <section id="contact">
      <!-- <h2 class="headline">Contact</h2>
      <v-divider class="my-4"></v-divider> -->
      <v-row class="d-flex flex-column" align="center">
        <v-col cols="12" md="6" align="center">
          <v-card class="my-3" elevation="2" color="brown-lighten-3">
            <v-card-text>
              <p class="body-1">
                <v-icon size="24" color="red-darken-2" class="mr-2">mdi-email</v-icon><a href="mailto:egner.zach@gmail.com">egner.zach@gmail.com</a>
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" align="center">
          <v-card class="my-3" elevation="2" color="brown-lighten-3">
            <v-card-text>
              <p class="body-1">
                <v-icon size="24" color="blue-darken-2" class="mr-2">mdi-linkedin</v-icon>
                <a href="https://www.linkedin.com/in/zach-egner" target="_blank" rel="noopener noreferrer">zach-egner</a>
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" align="center">
          <v-card class="my-3" elevation="2" color="brown-lighten-3">
            <v-card-text>
              <p class="body-1">
                <v-icon size="24" class="mr-2">mdi-github</v-icon>
                Personal: <a href="https://github.com/zachegner" target="_blank" rel="noopener noreferrer">zachegner</a>
              </p>
              <p class="body-1">
                <v-icon size="24" class="mr-2">mdi-github</v-icon>
                Work: <a href="https://github.com/zachegnermh" target="_blank" rel="noopener noreferrer">zachegnermh</a>
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
<!--       <UnderConstruction /> -->
    </section>
  </v-container>
</template>

<script>
//import UnderConstruction from './UnderConstruction.vue';

export default {
    name: "ContactMe",
    components: { /* UnderConstruction */ }
};
</script>

<style scoped>
/* Add your CSS styles here */
/* You can customize styles for specific elements here */
</style>
