<!-- src/components/UnderConstruction.vue -->
<template>
  <div class="under-construction">
    <v-icon size="100">mdi-wrench</v-icon>
    <h2>Under Construction</h2>
    <p>This section is currently under development. Check back soon for updates!</p>
  </div>
</template>

<script>
export default {
  name: "UnderConstruction",
};
</script>

<style scoped>
.under-construction {
  text-align: center;
  padding: 20px;
}

.under-construction h2 {
  font-size: 24px;
}

.under-construction p {
  font-size: 16px;
  margin-top: 10px;
}
</style>
