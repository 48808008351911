<template>
  <v-app>
    <!-- <div class="custom-toolbar">
    <div class="toolbar-item" @click="navigateTo('')">
      <i class="mdi mdi-view-dashboard"></i>
      <span>About Me</span>
    </div>
    <div class="toolbar-item" @click="navigateTo('projects')">
      <i class="mdi mdi-forum"></i>
      <span>Projects</span>
    </div>
    <div class="toolbar-item" @click="navigateTo('contact')">
      <i class="mdi mdi-account"></i>
      <span>Contact</span>
    </div>
  </div> -->
    <v-card>
      <v-layout>
        <v-main class="main">
          <router-view></router-view> <!-- Use router-view to display components -->
        </v-main></v-layout>
    </v-card>
    <v-footer class="bg-brown-lighten-1">
      <v-row justify="center" no-gutters>
        <v-btn v-for="link in links" :key="link" color="white" variant="text" class="mx-2" rounded="xl"
          @click="navigateTo(link.toLowerCase())">
          {{ link }}
        </v-btn>
        <v-col class="text-center mt-4" cols="12">
          {{ new Date().getFullYear() }} — <strong>Zach Egner</strong>
        </v-col>
        <v-col cols="12" class="text-center d-flex flex-column ">
          <div>
            <a href="mailto:egner.zach@gmail.com">
              <v-btn class="mx-4" icon="mdi-email" variant="text" color="red darken-2"></v-btn>
            </a>
            <a href="https://www.linkedin.com/in/zach-egner" target="_blank" rel="noopener noreferrer">
              <v-btn class="mx-4" icon="mdi-linkedin" variant="text" color="blue darken-2"></v-btn>
            </a>
            <a href="https://github.com/zachegner" target="_blank" rel="noopener noreferrer">
              <v-btn class="mx-4" icon="mdi-github" variant="text" color="black"></v-btn>
            </a>
          </div>
        </v-col>
      </v-row>
    </v-footer>
    <!-- <v-footer class="bg-brown-lighten-1 text-center d-flex flex-column">
      <div>
        <a href="mailto:egner.zach@gmail.com">
          <v-btn class="mx-4" icon="mdi-email" variant="text" color="red darken-2"></v-btn>
        </a>
        <a href="https://www.linkedin.com/in/zach-egner" target="_blank" rel="noopener noreferrer">
          <v-btn class="mx-4" icon="mdi-linkedin" variant="text" color="blue darken-2"></v-btn>
        </a>
        <a href="https://github.com/zachegner" target="_blank" rel="noopener noreferrer">
          <v-btn class="mx-4" icon="mdi-github" variant="text" color="black"></v-btn>
        </a>
      </div>
      <div>
        {{ new Date().getFullYear() }} — <strong>Zach Egner</strong>
      </div>
    </v-footer> -->
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      drawer: false, // For mobile navigation drawer
      icons: [
        'mdi-email',
        'mdi-linkedin',
        'mdi-github',
      ],
      links: [
        'Home',
        /* 'About',
        'Services',
        'Blog', */
        'Projects',
        'Contact',
      ],
    };
  },
  methods: {
    navigateTo(routeName) {
      this.$router.push('/' + routeName);
    },
  },
};
</script>

<style>
/* Add global styles here */
.custom-toolbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  /* Choose a background color that suits your style */
  color: black;
  /* Text color */
  height: 60px;
  /* Adjust the height as needed */
  margin-top: 20px;
}

.toolbar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.toolbar-item:hover {
  transform: translateY(-5px);
}

.toolbar-item i {
  font-size: 24px;
}

.toolbar-item span {
  font-size: 12px;
  margin-top: 5px;
}

a {
  text-decoration: none;
}

.main {
  background-image: url('./assets/fall.jpeg');
}
</style>
