<template>
  <v-container>
    <section id="projects">
      <h2 class="headline">Projects</h2>
      <v-divider class="my-4"></v-divider>
      <v-carousel cycle height="70vh" touch  show-arrows="hover">
        <a href="https://fit-trainer.zachegner.com" target="_blank"><v-carousel-item
          src="../assets/ss1.png"
          cover
        ></v-carousel-item></a>
        <!-- <v-carousel-item
          src="https://cdn.vuetifyjs.com/images/cards/hotel.jpg"
          cover
        ></v-carousel-item>

        <v-carousel-item
          src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
          cover
        ></v-carousel-item> -->
      </v-carousel>
      <!-- Add your project entries here -->
      <!-- <v-card class="my-4" elevation="2">
        <v-card-title>Project 1</v-card-title>
        <v-card-text>
          <p class="body-1">
            Description of Project 1 goes here. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit.
          </p>
        </v-card-text>
      </v-card>
      <v-card class="my-4" elevation="2">
        <v-card-title>Project 2</v-card-title>
        <v-card-text>
          <p class="body-1">
            Description of Project 2 goes here. Nullam nec justo ut justo
            egestas consequat non a tellus.
          </p>
        </v-card-text>
      </v-card> -->
      <!-- <UnderConstruction /> -->
      <!-- Add more project entries as needed -->
    </section>
  </v-container>
</template>

<script>
//import UnderConstruction from './UnderConstruction.vue';

export default {
    name: "MyProjects",
    components: { 
      //UnderConstruction 
    }
};
</script>

<style scoped>
/* Add your CSS styles here */
/* You can customize styles for specific elements here */
</style>
