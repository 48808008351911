import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"; // Import Vue Router
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import "vuetify/dist/vuetify.min.css"; // Import Vuetify CSS

loadFonts()

createApp(App)
  .use(vuetify)
  .use(router)
  .mount('#app')
